// Analytics event constants organized by feature area

// Authentication & Login
export const evt_login_page_visit = 'login_page_visit';
export const evt_login_with_email_click = 'login_with_email_click';
export const evt_login_with_google_click = 'login_with_google_click';
export const evt_login_remember_me_click = 'login_remember_me_click';

// Registration & Signup
export const evt_signup_page_visit = 'signup_page_visit';
export const evt_signup_with_email_click = 'signup_with_email_click';
export const evt_signup_with_google_click = 'signup_with_google_click';
export const evt_forgot_password_page_visit = 'forgot_password_page_visit';
export const evt_verify_reset_email_page_visit = 'verify_reset_email_page_visit';

// Account Setup & Onboarding
export const evt_account_setup_visit = 'account_setup_visit';
export const evt_account_setup_complete = 'account_setup_complete';
export const evt_account_setup_skip_invite = 'account_setup_skip_invite';
export const evt_account_setup_template_complete = 'account_setup_template_complete';

// Password Management
export const evt_reset_password_click = 'reset_password_click';

// Project Management
export const evt_projects_page_visit = 'projects_page_visit';
export const evt_projects_create_click = 'projects_create_click';
export const evt_projects_create = 'projects_create';
export const evt_projects_refresh_click = 'projects_refresh_click';
export const evt_projects_search = 'projects_search';
export const evt_projects_archive = 'projects_archive';
export const evt_projects_unarchive = 'projects_unarchive';
export const evt_projects_settings_click = 'projects_settings_click';
export const evt_projects_archive_all = 'projects_archive_all';
export const evt_projects_unarchive_all = 'projects_unarchive_all';

// Project Views & Navigation
export const evt_project_board_visit = 'project_board_visit';
export const evt_project_workload_visit = 'project_workload_visit';
export const evt_project_roadmap_visit = 'project_roadmap_visit';
export const evt_project_insights_overview_visit = 'project_insights_overview_visit';
export const evt_project_insights_members_visit = 'project_insights_members_visit';
export const evt_project_insights_tasks_visit = 'project_insights_tasks_visit';
export const evt_project_files_visit = 'project_files_visit';
export const evt_project_members_visit = 'project_members_visit';

// Project Actions
export const evt_project_task_create = 'project_task_create';
export const evt_project_invite_members_click = 'project_invite_members_click';
export const evt_project_invite_members = 'project_invite_members';
export const evt_project_refresh_click = 'project_refresh_click';
export const evt_project_settings_click = 'project_settings_click';
export const evt_project_import_tasks_click = 'project_import_tasks_click';
export const evt_project_import_tasks = 'project_import_tasks';
export const evt_project_update = 'project_update';

// Board Interactions
export const evt_project_board_open_task = 'project_board_open_task';
export const evt_project_board_transition_task = 'project_board_transition_task';
export const evt_project_board_update_task_order = 'project_board_update_task_order';
export const evt_project_board_column_setting_click = 'project_board_column_setting_click';
export const evt_project_board_create_status_click = 'project_board_create_status_click';
export const evt_project_board_create_status = 'project_board_create_status';
export const evt_project_board_create_task_click = 'project_board_create_task_click';

// Task List Management
export const evt_project_task_list_visit = 'project_task_list_visit';
export const evt_project_task_list_show_archived = 'project_task_list_show_archived';
export const evt_project_task_list_bulk_change_status = 'project_task_list_bulk_change_status';
export const evt_project_task_list_bulk_change_priority = 'project_task_list_bulk_change_priority';
export const evt_project_task_list_bulk_change_phase = 'project_task_list_bulk_change_phase';
export const evt_project_task_list_bulk_update_labels = 'project_task_list_bulk_update_labels';
export const evt_project_task_list_bulk_assign_me = 'project_task_list_bulk_assign_me';
export const evt_project_task_list_bulk_assign_members = 'project_task_list_bulk_assign_members';
export const evt_project_task_list_bulk_archive = 'project_task_list_bulk_archive';
export const evt_project_task_list_bulk_delete = 'project_task_list_bulk_delete';
export const evt_project_task_list_context_menu_assign_me =
  'project_task_list_context_menu_assign_me';
export const evt_project_task_list_context_menu_archive = 'project_task_list_context_menu_archive';
export const evt_project_task_list_context_menu_delete = 'project_task_list_context_menu_delete';
export const evt_project_task_list_create_task = 'project_task_list_create_task';
export const evt_project_task_list_create_subtask = 'project_task_list_create_subtask';
export const evt_project_task_list_open_task = 'project_task_list_open_task';
export const evt_project_task_list_drag_and_move = 'project_task_list_drag_and_move';
export const evt_project_task_list_show_fields = 'project_task_list_show_fields';
export const evt_project_task_list_search_task = 'project_task_list_search_task';

// Team & People Management
export const evt_people_page_visit = 'people_page_visit';
export const evt_people_refresh_click = 'people_refresh_click';
export const evt_people_search = 'people_search';
export const evt_people_create_click = 'people_create_click';
export const evt_people_click = 'people_click';
export const evt_people_create = 'people_create';
export const evt_people_delete = 'people_delete';
export const evt_people_activate = 'people_activate';
export const evt_people_deactivate = 'people_deactivate';
export const evt_project_import_from_template_click = 'project_import_from_template_click';

// Schedule & Planning
export const evt_schedule_page_visit = 'schedule_page_visit';

// Workload Management
export const evt_workload_drag_change_date = 'workload_drag_change_date';
export const evt_workload_drag_move = 'workload_drag_move';
export const evt_workload_task_open = 'workload_task_open';

// Roadmap Features
export const evt_roadmap_task_create = 'roadmap_task_create';
export const evt_roadmap_sub_task_create = 'roadmap_sub_task_create';
export const evt_roadmap_drag_change_date = 'roadmap_drag_change_date';
export const evt_roadmap_drag_move = 'roadmap_drag_move';
export const evt_roadmap_task_open = 'roadmap_task_open';
export const evt_roadmap_task_drag_n_sort = 'roadmap_task_drag_n_sort';

// Settings & Configuration
export const evt_settings_profile_visit = 'settings_profile_visit';
export const evt_settings_profile_avatar_upload = 'settings_profile_avatar_upload';
export const evt_settings_profile_name_change = 'settings_profile_name_change';
export const evt_settings_notifications_visit = 'settings_notifications_visit';
export const evt_settings_clients_visit = 'settings_clients_visit';
export const evt_settings_job_titles_visit = 'settings_job_titles_visit';
export const evt_settings_labels_visit = 'settings_labels_visit';
export const evt_settings_categories_visit = 'settings_categories_visit';
export const evt_settings_task_templates_visit = 'settings_task_templates_visit';
export const evt_settings_teams_visit = 'settings_teams_visit';
export const evt_settings_change_password_visit = 'settings_change_password_visit';
export const evt_settings_language_and_region_visit = 'settings_language_and_region_visit';
export const evt_settings_language_changed = 'settings_language_changed';
export const evt_settings_profile_update = 'settings_profile_update';
export const evt_settings_notifications_update = 'settings_notifications_update';
export const evt_settings_clients_create = 'settings_clients_create';
export const evt_settings_job_titles_create = 'settings_job_titles_create';
export const evt_settings_labels_delete = 'settings_labels_delete';
export const evt_settings_category_delete = 'settings_category_delete';
export const evt_settings_task_templates_delete = 'settings_task_templates_delete';
export const evt_settings_profile_picture_update = 'settings_profile_picture_update';

// Common Actions
export const evt_common_switch_team = 'common_switch_team';
export const evt_common_display_notifications = 'common_display_notifications';
export const evt_common_logout = 'common_logout';

// Analytics & Reporting
export const evt_reporting_overview = 'reporting_overview_visit';
export const evt_reporting_allocation = 'reporting_allocation_visit';
export const evt_reporting_projects_overview = 'reporting_projects_overview_visit';
export const evt_reporting_projects_custom = 'reporting_projects_custom_visit';

// Billing & Subscription
export const evt_billing_current_bill = 'billing_current_bill';
export const evt_billing_configuration = 'billing_configuration';
export const evt_billing_view_plans_modal = 'billing_view_plans_modal';
export const evt_billing_pause_plan = 'billing_pause_plan';
export const evt_billing_resume_plan = 'billing_resume_plan';
export const evt_billing_add_more_seats = 'billing_add_more_seats';

// Admin Center
export const evt_admin_center_teams_visit = 'admin_center_teams_visit';
export const evt_admin_center_users_visit = 'admin_center_users_visit';
export const evt_admin_center_overview_visit = 'admin_center_overview_visit';
export const evt_admin_center_teams_delete = 'admin_center_teams_delete';
export const evt_admin_center_team_settings = 'admin_center_team_settings';
export const evt_admin_center_projects_visit = 'admin_center_projects_visit';

// Project Preferences
export const evt_project_default_view_pinned = 'pin_default_project_view';
