export const projectColors: string[] = [
  '#164c9b',
  '#3b7ad4',
  '#70a6f3',
  '#7781ca',
  '#9877ca',
  '#c178c9',
  '#ee87c5',
  '#ca7881',
  '#75c9c0',
  '#75c997',
  '#80ca79',
  '#aacb78',
  '#cbbc78',
  '#cb9878',
  '#bb774c',
  '#905b39',
  '#903737',
  '#bf4949',
  '#f37070',
  '#ff9c3c',
  '#fbc84c',
  '#cbc8a1',
  '#a9a9a9',
  '#767676',
];
